import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { ToggleOnOff } from "./toggleOnOff"
import { Button } from "./button"
import "../styles/cookie-consent.scss"

export const CookieConsent = React.memo(({ openBanner, closeBanner }) => {
  const cookieNames = {
    statistics: "statisticsEnabled",
  }
  const [statisticsToggleValue, setStatisticsToggleValue] = useState(true)

  useEffect(() => {
    try {
      const allowStatisticsCookieValue = Cookies.get(cookieNames.statistics)
      if (
        allowStatisticsCookieValue === null ||
        allowStatisticsCookieValue === undefined
      ) {
          setTimeout(() =>
          {
              openBanner()
          }, [1000])
      }
    } catch (e) {
      console.warn(e)
    }
  }, [])

  const handleSave = () => {
    try {
      const expirationTime = statisticsToggleValue ? 360 : 7
      Cookies.set(cookieNames.statistics, statisticsToggleValue, {
        expires: expirationTime,
      })
      if (statisticsToggleValue === true) {
        if (window.dataLayer) {
          try {
            window.dataLayer.push({
              event: "statisticsEnabled",
              page: {
                path: window.location.pathname,
              },
            })
          } catch {}
        }
      }
    } catch (e) {
      console.warn(e)
    }
    closeBanner()
  }

  return (
    <div>
      <h2>Cookie Consent</h2>
      <p>
        This site would like to use Google Analytics to analyze site usage such
        as page views and user interactions to improve the site and user
        experience. Choose below if you'd like to allow collecting site
        statistics.
      </p>
      <ToggleOnOff
        label="Allow statistics"
        value={statisticsToggleValue}
        onClick={() => {
          setStatisticsToggleValue(!statisticsToggleValue)
        }}
      />
      <div>
        <Button onClick={handleSave} className="c-cookie-consent__button">
          Save
        </Button>
      </div>
    </div>
  )
})
