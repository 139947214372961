import React, { cloneElement, useState } from "react"
import "../styles/banner.scss"

export const Banner = ({ className = "", children }) => {
  const [isBannerOpen, setIsBannerOpen] = useState(false)
  if (!children) return null

  const openBanner = () => setIsBannerOpen(true)

  const closeBanner = () => setIsBannerOpen(false)

  return (
    <div
      className={`c-banner ${className} ${isBannerOpen ? "open" : "closed"}`}
    >
      <button onClick={closeBanner} className="c-banner__close-button">close</button>
      <div className="c-banner__content">
        {cloneElement(children, { openBanner, closeBanner }, null)}
      </div>
    </div>
  )
}
