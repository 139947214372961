import React from "react"
import { Link } from "gatsby"
import "../styles/navigation.scss"

export const Navigation = () => {
    return (
        <ul className="c-navigation">
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/blog">Blog</Link>
            </li>
        </ul>
    )
}