/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { Banner } from "../components/banner"
import { CookieConsent } from "../components/cookieConsent"
import { Navigation } from "../components/navigation"

export const Layout = ({ children }) => {
  return (
    <div>
        <Banner>
            <CookieConsent />
        </Banner>
        <Navigation></Navigation>
      <main>{children}</main>
    </div>
  )
}