import React from "react"
import "../styles/toggle-on-off.scss"

export const ToggleOnOff = ({ label, value, onClick, ...rest }) => {
  return (
    <div className={`c-toggle ${value === true ? "on" : "off"}`} {...rest}>
      <p className="c-toggle__text">{label}</p>
      <button className="c-toggle__button" onClick={onClick}>
        <span className="c-toggle__circle"></span>
      </button>
      <p className="c-toggle__text">{value === true ? "ON" : "OFF"}</p>
    </div>
  )
}
