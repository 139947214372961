import React from "react"
import "../styles/button.scss"

export const Button = ({ children, className = "", ...rest }) => {
  return (
    <button className={`c-button heading-m ${className}`} {...rest}>
      {children}
    </button>
  )
}
